import { DocumentType, Work } from "@biblioteksentralen/cordata";
import { Box, BoxProps, ChakraProps, HStack, Tag } from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { useSitePalette } from "../../utils/useSitePalette";
import { getTranslatedDocumentTypeLabel } from "@libry-content/integrations";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { CordataWork } from "@libry-content/integrations";
import { ResolvedPaletteColor } from "@libry-content/common";

type DocumentTypesProps = {
  work: Work;
  specifiedDocumentTypes?: DocumentType[]; //include if you want specific document types tagged, else it will list all document types existing in the work
} & BoxProps;

export const DocumentTypes = ({ work, specifiedDocumentTypes, ...boxProps }: DocumentTypesProps) => {
  const palette = useSitePalette();
  const documentTypeLabels = new CordataWork(work).getMediaTypeLabels();
  const { lang } = useTranslation();

  return (
    <Box {...boxProps}>
      <HStack as="ul" maxWidth="12rem" flexWrap="wrap" spacing="0.5rem">
        {documentTypeLabels.map((label) => (
          <TypeTag key={label} aria-label={label} styleColors={palette.colors.lightaccent3}>
            {getTranslatedDocumentTypeLabel(label, lang)}
          </TypeTag>
        ))}
      </HStack>
    </Box>
  );
};

export const TypeTag = ({
  children,
  styleColors,
  ...chakraProps
}: { children: ReactNode; styleColors: ResolvedPaletteColor } & ChakraProps) => {
  return (
    <Tag
      as="li"
      whiteSpace="nowrap"
      textTransform="uppercase"
      fontSize="2xs" // TODO: Is this too small?
      lineHeight="1.2"
      fontWeight="semibold"
      minHeight="unset"
      padding="0.125rem 0.25rem"
      borderRadius="sm"
      minWidth="0"
      background={styleColors.css.background}
      color={styleColors.css.color}
      {...chakraProps}
    >
      {children}
    </Tag>
  );
};
