export const safelyParseJSONObject = (params: Record<string, string>) =>
  Object.fromEntries(Object.entries(params).map(([key, value]) => [key, safeParseJSON(value)]));

const safeParseJSON = (value: string) => {
  try {
    return JSON.parse(value);
  } catch (e) {
    // Handle invalid JSON
    return undefined;
  }
};
